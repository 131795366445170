// 常量
// 请求地址
// export const MAIN_URL = process.env.NODE_ENV === 'development'
//   ? 'http://192.168.31.131:8084/asset-api-gateway/companyGateWay/api'// 开发
//   : 'https://ops.svlead.com/asset-api-gateway/companyGateWay/api' // 生产
//

// 打包时间
export const BUILD_TIME = process.env.VUE_APP_BUILD_TIME

// eslint-disable-next-line no-undef
const developmentUrl = globalThis.devConfig && globalThis.devConfig.apiUrl // 开发
const productionUrl = 'https://ops.svlead.com/asset-api-gateway/companyGateWay/api' // 生产
export const MAIN_URL = process.env.NODE_ENV === 'development' ? developmentUrl : productionUrl
// export const MAIN_URL = 'https://ops.svlead.com/asset-api-gateway/companyGateWay/api'
// export const MAIN_URL = 'https://kf.uhi-networks.com/asset-api-gateway/companyGateWay/api'
// 工单的小状态
export const smallOrderState = [
  { value: '5', name: '已转移', label: '已转移' },
  { value: '6', name: '已拒单', label: '已拒单' },
  { value: '7', name: '已升级', label: '已升级' },
  { value: '8', name: '已暂停', label: '已暂停' },
  { value: '12', name: '完成', label: '完成' }
]
export const UPLOAD_ACTION = 'https://oss-cn-beijing.aliyuncs.com'
// 页面刷新间隔时间
export const REFRESHTIME = 600000
// 发送验证码设置
export const VERIFICODE_SECOND = 10
export const PAGE = 1
export const LIMIT = 15
export const PAGESIZE = [15, 30, 50, 100]
export const SELECT_LIMIT = 50
export const EARLIEAS = 2019 // 查询资产盘点最早的年份
export const MAXUPLOADLENGTH = 5 // 上传文件数量限制

export const CROP_IMG_SIZE = {
  // 注册时营业执照
  bsLicense: {
    w: 60,
    h: 60
  }
}
/** ***********************************************************************************************************************************/
// 发送短信验证码
export const HXCS_YW_FSDX = 'HXCS_YW_FSDX'
// 短信验证码验证
export const HXCS_QY_DXYZ = 'HXCS_QY_DXYZ'
// 企业找回密码
export const HXCS_QY_QYMMXG = 'HXCS_QY_QYMMXG'
// 获取阿里OOS上传参数
export const HXCS_QY_OSST = 'HXCS_QY_OSST'
// 企业信息注册
export const HXCS_QY_QYZC = 'HXCS_QY_QYZC'

// 企业用户名验证
export const HXCS_QY_QYYZYH = 'HXCS_QY_QYYZYH'
// 企业用户登录
export const HXCS_QY_QYDL = 'HXCS_QY_QYDL'
// 企业详细信息
export const HXCS_QY_QYXX = 'HXCS_QY_QYXX'
// 企业信息修改
export const HXCS_QY_QYXG = 'HXCS_QY_QYXG'
// 企业修改找回密码
export const HXCS_QY_XGMM = 'HXCS_QY_XGMM'
// 企业登录后菜单信息
export const HXCS_QY_DLCD = 'HXCS_QY_DLCD'
// 企业角色列表信息
export const HXCS_QY_JSLB = 'HXCS_QY_JSLB'
// 企业新增或修改角色信息
export const HXCS_QY_BJJS = 'HXCS_QY_BJJS'
// 企业删除角色信息
export const HXCS_QY_SCJS = 'HXCS_QY_SCJS'
// 企业角色菜单配置信息
export const HXCS_QY_JSCD = 'HXCS_QY_JSCD'
// 企业角色菜单配置信息保存
export const HXCS_QY_JSCDBC = 'HXCS_QY_JSCDBC'
// 企业部门组织列表信息
export const HXCS_QY_BMLB = 'HXCS_QY_BMLB'
// 企业新增或修改部门组织信息
export const HXCS_QY_BJBM = 'HXCS_QY_BJBM'
// 企业删除部门组织信息
export const HXCS_QY_SCBM = 'HXCS_QY_SCBM'
// 企业用户列表信息
export const HXCS_QY_YHLB = 'HXCS_QY_YHLB'
// 企业新增或修改用户信息
export const HXCS_QY_BJYH = 'HXCS_QY_BJYH'
// 企业删除用户信息
export const HXCS_QY_SCYH = 'HXCS_QY_SCYH'
// 企业用户自身密码修改
export const HXCS_QY_YHMM = 'HXCS_QY_YHMM'
// 企业用户拥有角色配置信息
export const HXCS_QY_YHJS = 'HXCS_QY_YHJS'
// 企业用户角色配置信息保存
export const HXCS_YW_YHJSBC = 'HXCS_YW_YHJSBC'
// 企业用户拥有部门配置信息
export const HXCS_YW_RYKHLB = 'HXCS_YW_RYKHLB'
// 企业用户部门配置信息保存
export const HXCS_YW_RYKHBC = 'HXCS_YW_RYKHBC'

// 企业用户退出登录
export const HXCS_QY_TCDL = 'HXCS_QY_TCDL'
// 企业邀请用户主动注册
export const HXCS_QY_YQZC = 'HXCS_QY_YQZC'
// 企业邀请用户主动注册链接验证
export const HXCS_QY_YQZCYZ = 'HXCS_QY_YQZCYZ'
// 企业邀请用户注册信息保存
export const HXCS_QY_YQZCSC = 'HXCS_QY_YQZCSC'
// 企业字典列表信息
export const HXCS_QY_ZDLB = 'HXCS_QY_ZDLB'
// 企业字典信息删除
export const HXCS_QY_ZDSC = 'HXCS_QY_ZDSC'
// 企业字典信息新增或编辑
export const HXCS_QY_ZDAB = 'HXCS_QY_ZDAB'

/** ******************************************************************资产运维管理系统************************************/
// 根据关键字检索服务商
export const HXCS_YW_JSQY = 'HXCS_YW_JSQY'
// 添加服务商
export const HXCS_YW_TJFW = 'HXCS_YW_TJFW'
// 服务商列表信息
export const HXCS_YW_FWLB = 'HXCS_YW_FWLB'
// 服务商授权本企业部门组织
export const HXCS_YW_FWSQZ = 'HXCS_YW_FWSQZ'
// 服务商移除
export const HXCS_YW_SCFW = 'HXCS_YW_SCFW'
// 指标设定-完工时限(SLA)列表信息
export const HXCS_YW_FWSLAL = 'HXCS_YW_FWSLAL'
// 指标设定-完工时限(SLA)新增或修改
export const HXCS_YW_FWSLAA = 'HXCS_YW_FWSLAA'
// 指标设定-完工时限(SLA)删除
export const HXCS_YW_FWSLAS = 'HXCS_YW_FWSLAS'
// 指标设定-接单时限列表信息
export const HXCS_YW_FWYXJL = 'HXCS_YW_FWYXJL'
// 指标设定-服务优先级新增或修改
export const HXCS_YW_FWYXJA = 'HXCS_YW_FWYXJA'
// 指标设定-服务优先级删除
export const HXCS_YW_FWYXJS = 'HXCS_YW_FWYXJS'
// 指标设定-服务分类列表信息
export const HXCS_YW_FWFLL = 'HXCS_YW_FWFLL'
// 指标设定-服务分类新增或修改
export const HXCS_YW_FWFLA = 'HXCS_YW_FWFLA'
// 指标设定-服务分类删除
export const HXCS_YW_FWFLS = 'HXCS_YW_FWFLS'
// 指标设定-故障分类列表信息
export const HXCS_YW_FWGZL = 'HXCS_YW_FWGZL'
// 指标设定-故障分类新增或修改
export const HXCS_YW_FWGZA = 'HXCS_YW_FWGZA'
// 指标设定-故障分类删除
export const HXCS_YW_FWGZS = 'HXCS_YW_FWGZS'
// 指标设定-影响范围列表信息
export const HXCS_YW_FWYXL = 'HXCS_YW_FWYXL'
// 指标设定-影响范围新增或修改
export const HXCS_YW_FWYXA = 'HXCS_YW_FWYXA'
// 指标设定-影响范围删除
export const HXCS_YW_FWYXS = 'HXCS_YW_FWYXS'
// 指标设定-到达时限列表信息
export const HXCS_YW_DDJBL = 'HXCS_YW_DDJBL'
// 指标设定-到达时限新增或修改
export const HXCS_YW_DDJBA = 'HXCS_YW_DDJBA'
// 指标设定-到达时限删除
export const HXCS_YW_DDJBS = 'HXCS_YW_DDJBS'
// 指标设定-app功能点信息
export const HXCS_QY_APPGNX = 'HXCS_QY_APPGNX'
// 指标设定-app功能点新增或修改
export const HXCS_QY_APPGNE = 'HXCS_QY_APPGNE'
// 指标设定-小程序功能信息
export const HXCS_QY_XCXGNX = 'HXCS_QY_XCXGNX'
// 指标设定-小程序功能新增或编辑
export const HXCS_QY_XCXGNE = 'HXCS_QY_XCXGNE'
// 运维管理-功能控制-企业工单是否发送邮件设置
export const HXCS_QY_YXFSSZ = 'HXCS_QY_YXFSSZ'
// 运维管理-工作日历列表信息(按月显示天）
export const HXCS_YW_GZRL = 'HXCS_YW_GZRL'
// 运维管理-工作日历列表信息(按年显示月）
export const HXCS_YW_GZYRL = 'HXCS_YW_GZYRL'
// 运维管理-考勤列表信息
export const HXCS_YW_KQLB = 'HXCS_YW_KQLB'
// 运维管理-个人考勤列表信息
export const HXCS_YW_GRKQLB = 'HXCS_YW_GRKQLB'

// 部门管理-部门组织及配置运维组单个新增或修改
export const HXCS_YW_BMBA = 'HXCS_YW_BMBA'
// 部门管理-部门组织及配置运维组批量修改
export const HXCS_YW_BMPB = 'HXCS_YW_BMPB'
// 部门管理-部门组织及配置运维组列表信息
export const HXCS_YW_BMLB = 'HXCS_YW_BMLB'
// 部门管理-部门组织及配置运维组详细信息
export const HXCS_YW_BMXQ = 'HXCS_YW_BMXQ'
// 部门管理-删除部门组织及配置运维组
export const HXCS_YW_BMSC = 'HXCS_YW_BMSC'
// 运维群组-列表信息
export const HXCS_YW_YWQZL = 'HXCS_YW_YWQZL'
// 运维群组-新增或修改
export const HXCS_YW_YWQZA = 'HXCS_YW_YWQZA'
// 运维群组-userName排重验证
export const HXCS_YW_YWYZ = 'HXCS_YW_YWYZ'
// 运维群组-删除信息
export const HXCS_YW_YWQSC = 'HXCS_YW_YWQSC'
// 运维群组-部门列表信息
export const HXCS_YW_YWQBM = 'HXCS_YW_YWQBM'
// 运维群组-资产信息
export const HXCS_YW_YWQZZ = 'HXCS_YW_YWQZZ'
// 服务配置-部门组织列表信息，包含授权的组织
export const HXCS_YW_FWPBB = 'HXCS_YW_FWPBB'
// 服务配置-资产配置运维组新增或修改
export const HXCS_YW_FWPZA = 'HXCS_YW_FWPZA'
// 服务配置-资产配置运维组详细信息
export const HXCS_YW_FWPZX = 'HXCS_YW_FWPZX'
// 资产管理-资产状态列表信息
export const HXCS_YW_ZCZZL = 'HXCS_YW_ZCZZL'
// 资产管理-内部资产列表信息
export const HXCS_YW_ZCNBL = 'HXCS_YW_ZCNBL'
// 资产管理-外部资产列表信息
export const HXCS_YW_ZCWBL = 'HXCS_YW_ZCWBL'
// 资产管理-资产信息新增或修改
export const HXCS_YW_ZCBJC = 'HXCS_YW_ZCBJC'
// 运维群组-所有一级工程师列表信息
export const HXCS_YW_YWQGC = 'HXCS_YW_YWQGC'
// 资产管理-资产备注新增
export const HXCS_YW_ZCBZX = 'HXCS_YW_ZCBZX'
// 资产管理-资产信息批量修改
export const HXCS_YW_ZCPLX = 'HXCS_YW_ZCPLX'
// 资产管理-资产详情信息
export const HXCS_YW_ZCNBX = 'HXCS_YW_ZCNBX'
// 资产管理-资产信息删除
export const HXCS_YW_ZCNBS = 'HXCS_YW_ZCNBS'
// 工单管理-工单列表信息
export const HXCS_YW_GDLB = 'HXCS_YW_GDLB'
// 工单管理-工单信息新增或修改
export const HXCS_YW_GDXB = 'HXCS_YW_GDXB'
// 工单管理-工单详情信息
export const HXCS_YW_GDXQ = 'HXCS_YW_GDXQ'
// 工单管理-工单处理过程记录信息
export const HXCS_YW_GDCL = 'HXCS_YW_GDCL'
// 工单管理-工单信息删除
export const HXCS_YW_GDSC = 'HXCS_YW_GDSC'
// 工单管理-工单备注新增
export const HXCS_YW_GDBZXZ = 'HXCS_YW_GDBZXZ'
// 工单退单（审核中）信息编辑
export const HXCS_YW_GDTDBJ = 'HXCS_YW_GDTDBJ'
// 知识库-知识库类型列表信息
export const HXCS_YW_ZSLXLB = 'HXCS_YW_ZSLXLB'
// 知识库-知识库类型新增或编辑信息
export const HXCS_YW_ZSLXAB = 'HXCS_YW_ZSLXAB'
// 知识库-知识库类型信息删除
export const HXCS_YW_ZSLXSC = 'HXCS_YW_ZSLXSC'
// 知识库-知识库导入
export const HXCS_YW_ZSDR = 'HXCS_YW_ZSDR'
// 知识库-知识库列表信息
export const HXCS_YW_ZSLB = 'HXCS_YW_ZSLB'
// 知识库-知识库详细信息
export const HXCS_YW_ZSXQ = 'HXCS_YW_ZSXQ'
// 知识库-知识库新增或编辑信息
export const HXCS_YW_ZSAB = 'HXCS_YW_ZSAB'
// 知识库-知识库信息删除
export const HXCS_YW_ZSSC = 'HXCS_YW_ZSSC'

// 客户信息导入
export const HXCS_YW_KHDR = 'HXCS_YW_KHDR'
// 客户信息备注
export const HXCS_YW_KHBZX = 'HXCS_YW_KHBZX'
// 客户管理-客户组列表信息
export const HXCS_YW_KHZLB = 'HXCS_YW_KHZLB'
// 客户管理-客户组信息新增或修改
export const HXCS_YW_KHZAE = 'HXCS_YW_KHZAE'
// 客户管理-客户组所选客户列表信息
export const HXCS_YW_KHZKH = 'HXCS_YW_KHZKH'
// 客户管理-客户组信息删除
export const HXCS_YW_KHZSC = 'HXCS_YW_KHZSC'
// 人员新增备注信息
export const HXCS_YW_RYBZX = 'HXCS_YW_RYBZX'
// 人员备注信息列表
export const HXCS_YW_RYBZL = 'HXCS_YW_RYBZL'
// 人员导入信息
export const HXCS_QY_YHDR = 'HXCS_QY_YHDR'
// 资产信息导入
export const HXCS_YW_ZCDR = 'HXCS_YW_ZCDR'
// 资产管理-资产类型品牌型号列表信息
export const HXCS_YW_ZCPXLB = 'HXCS_YW_ZCPXLB'
// 资产管理-资产类型品牌型号新增或编辑信息
export const HXCS_YW_ZCPXAE = 'HXCS_YW_ZCPXAE'
// 资产管理-资产类型品牌型号信息删除
export const HXCS_YW_ZCPXSC = 'HXCS_YW_ZCPXSC'
// 资产管理-资产类型品牌型号信息导入
export const HXCS_YW_ZCPXDR = 'HXCS_YW_ZCPXDR'
// 资产管理-配件使用列表信息
export const HXCS_YW_PJSLB = 'HXCS_YW_PJSLB'
// 资产管理-配件库列表信息
export const HXCS_YW_PJKLB = 'HXCS_YW_PJKLB'
// 资产管理-配件库信息新增或修改
export const HXCS_YW_PJKXB = 'HXCS_YW_PJKXB'
// 资产管理-配件库信息删除
export const HXCS_YW_PJKSC = 'HXCS_YW_PJKSC'
// 资产管理-配件库信息批量导入
export const HXCS_YW_PJKDR = 'HXCS_YW_PJKDR'

// 首页数量统计
export const HXCS_YW_SYZSTJ = 'HXCS_YW_SYZSTJ'
// 工程师工单量统计
export const HXCS_YW_SYSDTJ = 'HXCS_YW_SYSDTJ'
// 工单量统计
export const HXCS_YW_SYGDTJ = 'HXCS_YW_SYGDTJ'
// 故障分类统计
export const HXCS_YW_SYGZTJ = 'HXCS_YW_SYGZTJ'
// SLA达成统计
export const HXCS_YW_SYSLTJ = 'HXCS_YW_SYSLTJ'
// 首页-服务分类统计
export const HXCS_YW_SYFWTJ = 'HXCS_YW_SYFWTJ'
// 首页统计-客户报修统计
export const HXCS_YW_SYKHTJ = 'HXCS_YW_SYKHTJ'
// 删除oss 文件
export const HXCS_QY_OSSC = 'HXCS_QY_OSSC'

// 调度统计
// 顶部标签门店组
export const HXCS_YWT_MDTJ = 'HXCS_YWT_MDTJ'
// 顶部标签数量读取
export const HXCS_YWT_BQTJ = 'HXCS_YWT_BQTJ'
// 顶部标签服务商
export const HXCS_YWT_FWSTJ = 'HXCS_YWT_FWSTJ'
// 顶部标签工单状态
export const HXCS_YWT_GDZTJ = 'HXCS_YWT_GDZTJ'
// 工程师工作台
// 工程师—-工单及任务工单统计
export const HXCS_YWG_GDTJ = 'HXCS_YWG_GDTJ'
// 工程师—-列表工单及任务数量读取
export const HXCS_YWG_GCSTJ = 'HXCS_YWG_GCSTJ'
// 工程师—-门店统计
export const HXCS_YWG_MDTJ = 'HXCS_YWG_MDTJ'
// 工程师-服务分类统计
export const HXCS_YWG_FLTJ = 'HXCS_YWG_FLTJ'
// 工程师-工单分类统计
export const HXCS_YWG_GDFWTJ = 'HXCS_YWG_GDFWTJ'

// 管理员统计
// 工单-工单数量统计
export const HXCS_YWO_GDSL = 'HXCS_YWO_GDSL'
// 工单来源信息
export const HXCS_YWT_GDLYJ = 'HXCS_YWT_GDLYJ'
// 工单日期分布
export const HXCS_YWT_GDFBJ = 'HXCS_YWT_GDFBJ'
// 工单产品分类
export const HXCS_YWT_GDFLJ = 'HXCS_YWT_GDFLJ'
// 工单影响程度
export const HXCS_YWT_GDYXJ = 'HXCS_YWT_GDYXJ'
// 工单-工单金额合计
export const HXCS_YWT_ZGDTJ = 'HXCS_YWT_ZGDTJ'

// 任务工单-任务工单详细信息
export const HXCS_ZAPP_RWGDXQ = 'HXCS_ZAPP_RWGDXQ'
// 任务工单-任务工单保存信息
export const HXCS_ZAPP_RWGDBC = 'HXCS_ZAPP_RWGDBC'
// 任务工单-任务工单转移接口
export const HXCS_ZAPP_RWGDZY = 'HXCS_ZAPP_RWGDZY'
// 任务工单退回
export const HXCS_YW_RWGDTH = 'HXCS_YW_RWGDTH'
// 任务工单-任务工单接取
export const HXCS_ZAPP_RWGDJQ = 'HXCS_ZAPP_RWGDJQ'

// 任务管理-任务清单列表信息
export const HXCS_YW_RWQDL = 'HXCS_YW_RWQDL'
// 任务管理-任务清单新增或修改
export const HXCS_YW_RWQDE = 'HXCS_YW_RWQDE'
// 任务管理-任务清单删除
export const HXCS_YW_RWQDD = 'HXCS_YW_RWQDD'
// 任务管理-任务清单excel批量新增
export const HXCS_YW_RWQDP = 'HXCS_YW_RWQDP'
// 任务管理-任务列表信息
export const HXCS_YW_RWLB = 'HXCS_YW_RWLB'
// 任务管理-任务信息新增或修改
export const HXCS_YW_RWAE = 'HXCS_YW_RWAE'
// 任务管理-任务详细信息
export const HXCS_YW_RWXQ = 'HXCS_YW_RWXQ'
// 任务管理-任务信息删除
export const HXCS_YW_RWSC = 'HXCS_YW_RWSC'
// 任务管理-任务所选清单列表信息
export const HXCS_YW_RWXQDL = 'HXCS_YW_RWXQDL'
// 任务管理-任务工单列表
export const HXCS_YW_RWGDL = 'HXCS_YW_RWGDL'
// 任务管理-任务工单任务项列表
export const HXCS_YW_RWGDRW = 'HXCS_YW_RWGDRW'
// 任务管理-任务工单文件列表
export const HXCS_YW_RWGDWJ = 'HXCS_YW_RWGDWJ'
// 任务管理-任务文件列表
export const HXCS_YW_RWWJ = 'HXCS_YW_RWWJ'
// 任务管理-任务所选客户列表信息
export const HXCS_YW_RWKHL = 'HXCS_YW_RWKHL'
// 任务管理-任务工单派单
export const HXCS_YW_RWGDP = 'HXCS_YW_RWGDP'

// 资产看板-数量统计
export const HXCS_YW_ZCKBTJ = 'HXCS_YW_ZCKBTJ'
// 资产看板-资产状态统计
export const HXCS_YW_ZCZTTJ = 'HXCS_YW_ZCZTTJ'
// 资产看板-资产类型统计
export const HXCS_YW_ZCLXTJ = 'HXCS_YW_ZCLXTJ'
// 资产看板-资产品牌统计
export const HXCS_YW_ZCPPTJ = 'HXCS_YW_ZCPPTJ'
// 资产看板-资产盘点
export const HXCS_YW_ZCPDTJ = 'HXCS_YW_ZCPDTJ'
// 资产看板-资产分布
export const HXCS_YW_ZCFBTJ = 'HXCS_YW_ZCFBTJ'
// 问题管理-未知问题列表信息
export const HXCS_YW_WZWTLB = 'HXCS_YW_WZWTLB'
// 问题管理-已知问题列表信息
export const HXCS_YW_WZYZLB = 'HXCS_YW_WZYZLB'
// 问题管理-问题解决信息保存
export const HXCS_YW_WZWTJJ = 'HXCS_YW_WZWTJJ'
// 问题管理-已知问题信息新增或修改
export const HXCS_YW_YZWTBJ = 'HXCS_YW_YZWTBJ'
// 问题管理-已知问题删除信息
export const HXCS_YW_WZYZSC = 'HXCS_YW_WZYZSC'

// 微信支付
export const HXCS_QY_WXDD = 'HXCS_QY_WXDD'
// 查询微信支付
export const HXCS_QY_CXWXDD = 'HXCS_QY_CXWXDD'
// 查询付款订单
export const HXCS_QY_FKDD = 'HXCS_QY_FKDD'
// 发票信息保存
export const HXCS_QY_FPXX = 'HXCS_QY_FPXX'

// 支付宝支付
export const HXCS_QY_ALIPDD = 'HXCS_QY_ALIPDD'
// 查询支付宝支付
export const HXCS_QY_CXALDD = 'HXCS_QY_CXALDD'

// 业务咨询列表
export const HXCS_YW_YWZXLB = 'HXCS_YW_YWZXLB'
// 业务咨询处理
export const HXCS_YW_YWZXCL = 'HXCS_YW_YWZXCL'
// 业务新增咨询
export const HXCS_YW_YWZXXZ = 'HXCS_YW_YWZXXZ'
// 工单管理-工单关单-关单
export const HXCS_YW_GDGD = 'HXCS_YW_GDGD'
// 工单管理-工单关单-退回
export const HXCS_YW_GDGDTH = 'HXCS_YW_GDGDTH'
// 工单管理-工单退回记录信息
export const HXCS_YW_GDTHJL = 'HXCS_YW_GDTHJL'
// 邮件信息发送
export const HXCS_YW_YJFS = 'HXCS_YW_YJFS'
// 邮件发送记录信息
export const HXCS_YW_YJFSJL = 'HXCS_YW_YJFSJL'
// 系统未读消息列表信息
export const HXCS_QY_WDXXLB = 'HXCS_QY_WDXXLB'
// 系统已读消息设置
export const HXCS_QY_YDXX = 'HXCS_QY_YDXX'
// 工单管理—工单催单按钮
export const HXCS_YW_GDCD = 'HXCS_YW_GDCD'
// 工单管理—工单数量信息
export const HXCS_YW_GDSLTJ = 'HXCS_YW_GDSLTJ'
// 工单管理-增加备注工单处理过程记录信息
export const HXCS_YW_BCGDCL = 'HXCS_YW_BCGDCL'
// 资产运维人员信息列表
export const HXCS_YW_RYXL = 'HXCS_YW_RYXL'
// 工单是否被他人占用读取
export const HXCS_YW_HQGDZY = 'HXCS_YW_HQGDZY'
// 工单占用释放
export const HXCS_YW_SFGDZY = 'HXCS_YW_SFGDZY'
// 资产管理-资产折旧列表信息
export const HXCS_YW_ZCZJLB = 'HXCS_YW_ZCZJLB'
// 资产管理-资产折旧信息新增或编辑
export const HXCS_YW_ZCZJBJ = 'HXCS_YW_ZCZJBJ'
// 资产管理-资产折旧信息删除
export const HXCS_YW_ZCZJSC = 'HXCS_YW_ZCZJSC'
// 企业用户列表信息
export const HXCS_YW_YHLB = 'HXCS_YW_YHLB'
// 企业新增或修改用户信息
export const HXCS_YW_BJYH = 'HXCS_YW_BJYH'
// 工单管理-回访记录新增或编辑
export const HXCS_YW_GDHFBJ = 'HXCS_YW_GDHFBJ'
// 企业用户信息删除
export const HXCS_YW_YHSC = 'HXCS_YW_YHSC'
// 工单进度流程列表信息
export const HXCS_YW_GDLCLB = 'HXCS_YW_GDLCLB'
// 服务商—-服务商密码修改
export const HXCS_FW_FWMMXG = 'HXCS_FW_FWMMXG'
// 主站企业菜单列表信息
export const HXCS_ZZ_QYCDLB = 'HXCS_ZZ_QYCDLB'
// 主站新增或修改企业菜单信息
export const HXCS_ZZ_QYBJCD = 'HXCS_ZZ_QYBJCD'
// 主站删除企业菜单信息
export const HXCS_ZZ_QYSCCD = 'HXCS_ZZ_QYSCCD'
// 企业用户拥有部门配置信息
export const HXCS_QY_YHBM = 'HXCS_QY_YHBM'
// 企业用户部门配置信息保存
export const HXCS_QY_YHBMBC = 'HXCS_QY_YHBMBC'

// 海底捞-客户管理相关接口
// 客户信息列表
export const HXCS_CLI_KHXXLB = 'HXCS_CLI_KHXXLB'
// 客户信息编辑
export const HXCS_CLI_KHXXBJ = 'HXCS_CLI_KHXXBJ'
// 客户信息删除
export const HXCS_CLI_KHXXSC = 'HXCS_CLI_KHXXSC'
// 客户信息详情
export const HXCS_CLI_KHXXXQ = 'HXCS_CLI_KHXXXQ'
// 客户导入
export const HXCS_CLI_KHXXDR = 'HXCS_CLI_KHXXDR'
// 客户-门店管理-客户组关系存储
export const HXCS_YW_KHZGX = 'HXCS_YW_KHZGX'
// 合同列表
export const HXCS_CLI_HTXXLB = 'HXCS_CLI_HTXXLB'
// 合同导入
export const HXCS_CLI_HTXXDR = 'HXCS_CLI_HTXXDR'
// 合同编辑
export const HXCS_CLI_HTXXBJ = 'HXCS_CLI_HTXXBJ'
// 合同删除
export const HXCS_CLI_HTXXSC = 'HXCS_CLI_HTXXSC'
// 销售任务信息编辑
export const HXCS_CLI_XSRWBJ = 'HXCS_CLI_XSRWBJ'
// 销售任务信息列表
export const HXCS_CLI_XSRWLB = 'HXCS_CLI_XSRWLB'
// 销售任务信息详情
export const HXCS_CLI_XSRWXQ = 'HXCS_CLI_XSRWXQ'
// 销售任务信息删除
export const HXCS_CLI_XSRWSC = 'HXCS_CLI_XSRWSC'
// 销售任务结束
export const HXCS_CLI_XSRWJS = 'HXCS_CLI_XSRWJS'
// 销售任务信息处理
export const HXCS_CLI_XSRWCL = 'HXCS_CLI_XSRWCL'
// 库存明细
export const HXCS_CC_CKMX = 'HXCS_CC_CKMX'
// 出库信息列表
export const HXCS_CC_CKLB = 'HXCS_CC_CKLB'
// 入库信息列表
export const HXCS_CC_RKLB = 'HXCS_CC_RKLB'
// 订单列表信息
export const HXCS_CC_DDLB = 'HXCS_CC_DDLB'
// 设备清单状态信息修改
export const HXCS_CC_QDBJ = 'HXCS_CC_QDBJ'
// 入库信息导入
export const HXCS_CC_RKDR = 'HXCS_CC_RKDR'
// 入库状态处理
export const HXCS_CC_RKCL = 'HXCS_CC_RKCL'
// 出库状态处理
export const HXCS_CC_CKCL = 'HXCS_CC_CKCL'
// 设备类型导入
export const HXCS_CLI_SBLXDR = 'HXCS_CLI_SBLXDR'
// 设备清单删除
export const HXCS_XM_SBQDSC = 'HXCS_XM_SBQDSC'

// 项目立项相关接口
// 项目立项
export const HXCS_XM_XMTJ = 'HXCS_XM_XMTJ'
// (订单-项目立项及补单设备清单信息新增或编辑
export const HXCS_XM_XMQDBJ = 'HXCS_XM_XMQDBJ'
// 项目立项信息删除
export const HXCS_XM_XMSC = 'HXCS_XM_XMSC'
// 项目立项信息详情
export const HXCS_XM_XMJCXQ = 'HXCS_XM_XMJCXQ'
// 子级项目列表信息
export const HXCS_XM_ZXMLB = 'HXCS_XM_ZXMLB'
// 项目立项基础信息新增或编辑
export const HXCS_XM_XMJCBJ = 'HXCS_XM_XMJCBJ'
// 项目立项设备列表
export const HXCS_XM_XMSBXQ = 'HXCS_XM_XMSBXQ'
// 项目列表
export const HXCS_XM_FXMLB = 'HXCS_XM_FXMLB'
// 总项目实施列表信息
export const HXCS_XM_ZSSLB = 'HXCS_XM_ZSSLB'
// 子项目实施列表信息
export const HXCS_XM_CSSLB = 'HXCS_XM_CSSLB'
// 项目实施信息编辑
export const HXCS_XM_SSBJ = 'HXCS_XM_SSBJ'
// 项目回款信息列表
export const HXCS_CLI_HKJLLB = 'HXCS_CLI_HKJLLB'
// 项目回款信息编辑
export const HXCS_CLI_HKJLBJ = 'HXCS_CLI_HKJLBJ'
// 项目回款记录删除
export const HXCS_CLI_HKJLSC = 'HXCS_CLI_HKJLSC'
// 项目开票记录列表
export const HXCS_CLI_KPJLLB = 'HXCS_CLI_KPJLLB'
// 项目发票记录编辑
export const HXCS_CLI_KPJLBJ = 'HXCS_CLI_KPJLBJ'
// 项目开票记录删除
export const HXCS_CLI_KPJLSC = 'HXCS_CLI_KPJLSC'
// 项目回款列表
export const HXCS_CLI_XMHKLB = 'HXCS_CLI_XMHKLB'
// 设备信息列表 HXCS_CLI_SBLXLB
export const HXCS_CLI_SBLXLB = 'HXCS_CLI_SBLXLB'
// 补货订单删除
export const HXCS_XM_XMDDSC = 'HXCS_XM_XMDDSC'
// 回款记录列表
export const HXCS_XM_HKJLLB = 'HXCS_XM_HKJLLB'
// 项目回款信息编辑
export const HXCS_XM_HKJLBJ = 'HXCS_XM_HKJLBJ'
// 项目回款信息删除
export const HXCS_XM_HKJLSC = 'HXCS_XM_HKJLSC'
// 项目回款列表
export const HXCS_XM_XMHKLB = 'HXCS_XM_XMHKLB'
// 开票记录
export const HXCS_XM_KPJLLB = 'HXCS_XM_KPJLLB'
// 开票记录编辑
export const HXCS_XM_KPJLBJ = 'HXCS_XM_KPJLBJ'
// 开票记录删除
export const HXCS_XM_KPJLSC = 'HXCS_XM_KPJLSC'
// 采购管理
// 供应商列表信息
export const HXCS_GH_GHSLB = 'HXCS_GH_GHSLB'
// 供货商信息编辑
export const HXCS_GH_GHSBJ = 'HXCS_GH_GHSBJ'
// 供货商信息删除
export const HXCS_GH_GHSSC = 'HXCS_GH_GHSSC'
// 设备信息列表
export const HXCS_CLI_SBXHLB = 'HXCS_CLI_SBXHLB'
// 设备信息删除
export const HXCS_CLI_SBLXBJ = 'HXCS_CLI_SBLXBJ'
// 设备信息新建
export const HXCS_CLI_SBLXSC = 'HXCS_CLI_SBLXSC'
// 设备信息，查询品牌
export const HXCS_CLI_SBPPLB = 'HXCS_CLI_SBPPLB'
// 采购申请列表
export const HXCS_CG_SQLB = 'HXCS_CG_SQLB'
export const HXCS_CG_SQLB_NEW = 'HXCS_CG_SQLB_NEW'
// 采购清单编辑
export const HXCS_CG_CGQDBJ = 'HXCS_CG_CGQDBJ'
// 供应商采购设备清单信息列表
export const HXCS_CG_GYSLB = 'HXCS_CG_GYSLB'
// 供应商采购清单编辑
export const HXCS_CG_GYSBJ = 'HXCS_CG_GYSBJ'
// 设备供应商发货信息编辑
export const HXCS_CG_FHBJ = 'HXCS_CG_FHBJ'
// 财务结算
// 结算分类设定编辑
export const HXCS_CW_JSFLBJ = 'HXCS_CW_JSFLBJ'
// 结算分类设定编辑
export const HXCS_CW_JSFLSC = 'HXCS_CW_JSFLSC'
// 结算分类设定列表
export const HXCS_CW_JSFLLB = 'HXCS_CW_JSFLLB'
// 费用信息列表
export const HXCS_YW_FYLB = 'HXCS_YW_FYLB'
// 订单基本信息编辑
export const HXCS_XM_DDBJ = 'HXCS_XM_DDBJ'
// 计价项目列表信息
export const HXCS_XM_JJXMLB = 'HXCS_XM_JJXMLB'
// 项目结算设定编辑
export const HXCS_XM_XMJSBJ = 'HXCS_XM_XMJSBJ'
// 项目结算列表
export const HXCS_XM_XMJSLB = 'HXCS_XM_XMJSLB'
export const HXCS_XM_XMJSLBN = 'HXCS_XM_XMJSLBN'
// 任务管理-任务统计
export const HXCS_YW_RWTJ = 'HXCS_YW_RWTJ'
// 任务管理-实施费用统计
export const HXCS_YW_SSTJ = 'HXCS_YW_SSTJ'
// 系统日志
export const HXCS_XT_RZLB = 'HXCS_XT_RZLB'
// 待办事项
export const HXCS_FW_SYXSTJ = 'HXCS_FW_SYXSTJ'
// 字段变化记录信息查询
export const SY_BASE_ZDBHCX = 'SY_BASE_ZDBHCX'

// 工程师处理工单
// 工单接取信息
export const HXCS_ZAPP_GDJQ = 'HXCS_ZAPP_GDJQ'
// 工单拒单接口
export const HXCS_ZAPP_GDJD = 'HXCS_ZAPP_GDJD'
// 工单转移接口
export const HXCS_ZAPP_GDZY = 'HXCS_ZAPP_GDZY'
// 工单报告提交信息
export const HXCS_ZAPP_GDBG = 'HXCS_ZAPP_GDBG'
// 退单
export const HXCS_ZAPP_GDSJ = 'HXCS_ZAPP_GDSJ'

export const HXCS_XM_HKBBLB = 'HXCS_XM_HKBBLB'

export const HXCS_YW_XXLB = 'HXCS_YW_XXLB'
export const HXCS_YW_XXBJ = 'HXCS_YW_XXBJ'
export const HXCS_CC_CKHZLB = 'HXCS_CC_CKHZLB'
export const HXCS_XM_XMDDQX = 'HXCS_XM_XMDDQX'
export const HXCS_CC_SBXXDR = 'HXCS_CC_SBXXDR'
export const HXCS_XM_HKJHSC = 'HXCS_XM_HKJHSC'
export const HXCS_XM_HKJHBJ = 'HXCS_XM_HKJHBJ'
export const HXCS_XM_HKJHLB = 'HXCS_XM_HKJHLB'
export const HXCS_XM_DZJLQR = 'HXCS_XM_DZJLQR'
export const HXCS_XM_XMDZLB = 'HXCS_XM_XMDZLB'
export const HXCS_XM_DZJLBJ = 'HXCS_XM_DZJLBJ'
export const HXCS_XM_XMZCLB = 'HXCS_XM_XMZCLB'
export const HXCS_XM_DZLBXX = 'HXCS_XM_DZLBXX'
export const HXCS_XM_DZXXQR = 'HXCS_XM_DZXXQR'
export const HXCS_XM_XMJJSC = 'HXCS_XM_XMJJSC'
export const HXCS_XM_SBZCLB = 'HXCS_XM_SBZCLB'
export const HXCS_XM_RGZCLB = 'HXCS_XM_RGZCLB'
export const HXCS_XM_HKJLFP = 'HXCS_XM_HKJLFP'
export const HXCS_CC_HKXXDR = 'HXCS_CC_HKXXDR'
export const HXCS_XM_XMXXQX = 'HXCS_XM_XMXXQX'
export const HXCS_XM_JYFXONE = 'HXCS_XM_JYFXONE'
export const HXCS_XM_JYFXTHR = 'HXCS_XM_JYFXTHR'
export const HXCS_XM_JYFXFOU = 'HXCS_XM_JYFXFOU'
export const HXCS_XM_JYFXTWO = 'HXCS_XM_JYFXTWO'
export const HXCS_CC_CKDC = 'HXCS_CC_CKDC'
export const HXCS_CG_SBXXDC = 'HXCS_CG_SBXXDC'
export const HXCS_CC_KDGSLB = 'HXCS_CC_KDGSLB'
export const HXCS_XM_XMKPMX = 'HXCS_XM_XMKPMX'
export const HXCS_CC_XMKPDR = 'HXCS_CC_XMKPDR'
export const HXCS_XM_XMKPSQ = 'HXCS_XM_XMKPSQ'
export const HXCS_XM_KPSQBC = 'HXCS_XM_KPSQBC'
export const HXCS_XM_YRXMSB = 'HXCS_XM_YRXMSB'
export const HXCS_XM_KPCLLB = 'HXCS_XM_KPCLLB'
export const HXCS_XM_KPSQCL = 'HXCS_XM_KPSQCL'
export const HXCS_XM_KPSQTH = 'HXCS_XM_KPSQTH'
export const HXCS_XM_KPMXSC = 'HXCS_XM_KPMXSC'
export const HXCS_XM_KPJCXQ = 'HXCS_XM_KPJCXQ'
export const HXCS_CC_PLDRSQ = 'HXCS_CC_PLDRSQ'
export const HXCS_CC_KPSBSQ = 'HXCS_CC_KPSBSQ'
export const HXCS_XM_KPCZJL = 'HXCS_XM_KPCZJL'
export const HXCS_CC_PLDRCL = 'HXCS_CC_PLDRCL'
export const HXCS_XM_KPSQSC = 'HXCS_XM_KPSQSC'
export const HXCS_XM_KPSQTJ = 'HXCS_XM_KPSQTJ'
export const HXCS_XM_KPTHCK = 'HXCS_XM_KPTHCK'
export const HXCS_XM_THSQBC = 'HXCS_XM_THSQBC'/* 申请退货新增，填写物流单号 */
export const HXCS_XM_THSQLB = 'HXCS_XM_THSQLB'
export const HXCS_XM_SBTHSH = 'HXCS_XM_SBTHSH'
export const HXCS_XM_SBTHQS = 'HXCS_XM_SBTHQS'
export const HXCS_XM_YTHLB = 'HXCS_XM_YTHLB'
export const HXCS_XM_THSQMX = 'HXCS_XM_THSQMX'
export const HXCS_XM_XMTHHZ = 'HXCS_XM_XMTHHZ'/* 项目退货汇总 */
export const HXCS_XM_THSQXQ = 'HXCS_XM_THSQXQ'/* 退货详情 */
export const HXCS_XM_XMSBQX = 'HXCS_XM_XMSBQX'/* 取消设备 */
export const HXCS_XM_SBTHQX = 'HXCS_XM_SBTHQX'/* 取消设备退货 */
export const HXCS_XM_THPLSH = 'HXCS_XM_THPLSH'/* 批量退货驳回 */
export const HXCS_XM_THCZJL = 'HXCS_XM_THCZJL'
export const HXCS_XM_HKJLFPLB = 'HXCS_XM_HKJLFPLB'
export const HXCS_XM_HKFPJLQX = 'HXCS_XM_HKFPJLQX'
export const HXCS_XM_HKFPMX = 'HXCS_XM_HKFPMX'
export const HXCS_XM_HKJLDC = 'HXCS_XM_HKJLDC'
export const HXCS_YW_PPFLLB = 'HXCS_YW_PPFLLB'
export const HXCS_YW_PPFLBC = 'HXCS_YW_PPFLBC'
export const HXCS_YW_PPFLSC = 'HXCS_YW_PPFLSC'
export const HXCS_YW_PPBC = 'HXCS_YW_PPBC'
export const HXCS_YW_PPDC = 'HXCS_YW_PPDC'
export const HXCS_YW_PPDR = 'HXCS_YW_PPDR'
export const HXCS_YW_PPSC = 'HXCS_YW_PPSC'
export const HXCS_YW_CPXXLB = 'HXCS_YW_CPXXLB'
export const HXCS_YW_CPLB = 'HXCS_YW_CPLB'
export const HXCS_YW_CPFLBC = 'HXCS_YW_CPFLBC'
export const HXCS_YW_CPFLSC = 'HXCS_YW_CPFLSC'
export const HXCS_YW_CPSC = 'HXCS_YW_CPSC'
export const HXCS_YW_CPDR = 'HXCS_YW_CPDR'
export const HXCS_YW_CPBC = 'HXCS_YW_CPBC'
export const HXCS_YW_CPCSSC = 'HXCS_YW_CPCSSC'
export const HXCS_YW_CPTPSC = 'HXCS_YW_CPTPSC'
export const HXCS_YW_CPXLK = 'HXCS_YW_CPXLK'
export const HXCS_XM_TCBLLB = 'HXCS_XM_TCBLLB'
export const HXCS_XM_TCPZXMLB = 'HXCS_XM_TCPZXMLB'
export const HXCS_XM_TCBLBC = 'HXCS_XM_TCBLBC'
export const HXCS_XM_PZXMBC = 'HXCS_XM_PZXMBC'
export const HXCS_XM_TCBLSC = 'HXCS_XM_TCBLSC'
export const HXCS_YW_CSGCSGL = 'HXCS_YW_CSGCSGL'
export const HXCS_YW_CSGCSBC = 'HXCS_YW_CSGCSBC'
export const HXCS_YW_JSSHLB = 'HXCS_YW_JSSHLB'
export const HXCS_XM_JXPDLB = 'HXCS_XM_JXPDLB'
export const HXCS_YW_GDSQFK = 'HXCS_YW_GDSQFK'
export const HXCS_YW_JSSHCL = 'HXCS_YW_JSSHCL'
export const HXCS_XM_JXPDBC = 'HXCS_XM_JXPDBC'
export const HXCS_XM_JXPDBB = 'HXCS_XM_JXPDBB'
export const HXCS_YW_GDJSLB = 'HXCS_YW_GDJSLB'
export const HXCS_YW_GDBGBC = 'HXCS_YW_GDBGBC'
export const HXCS_YW_ZJZDSC = 'HXCS_YW_ZJZDSC'
export const HXCS_XM_KPCLJL = 'HXCS_XM_KPCLJL'
export const HXCS_YW_JYFXCSBC = 'HXCS_YW_JYFXCSBC'
export const HXCS_YW_JYFXCSLB = 'HXCS_YW_JYFXCSLB'
export const HXCS_XM_KPCLJLLB = 'HXCS_XM_KPCLJLLB'
export const HXCS_XM_JYFXFIV = 'HXCS_XM_JYFXFIV'
export const HXCS_YW_GDSHLB = 'HXCS_YW_GDSHLB'
export const HXCS_YW_YSDSHLB = 'HXCS_YW_YSDSHLB'
export const HXCS_YW_YSDSH = 'HXCS_YW_YSDSH'
export const HXCS_YW_YHLBNEW = 'HXCS_YW_YHLBNEW'
export const HXCS_YW_XMCBLB = 'HXCS_YW_XMCBLB'
export const HXCS_YW_CBBJ = 'HXCS_YW_CBBJ'
export const HXCS_YW_CBXMPZ = 'HXCS_YW_CBXMPZ'
export const HXCS_XM_CBPZXMLB = 'HXCS_XM_CBPZXMLB'
export const HXCS_ZAPP_FWGDCH = 'HXCS_ZAPP_FWGDCH'
export const HXCS_ZAPP_FWGDSC = 'HXCS_ZAPP_FWGDSC'
export const HXCS_ZAPP_FWFLL = 'HXCS_ZAPP_FWFLL' /* 服务类型 */
export const HXCS_ZAPP_KHZLB = 'HXCS_ZAPP_KHZLB'
export const HXCS_ZAPP_MDLB = 'HXCS_ZAPP_MDLB'
export const HXCS_ZAPP_FWGDTB = 'HXCS_ZAPP_FWGDTB'
export const HXCS_ZAPP_GDSHGD = 'HXCS_ZAPP_GDSHGD'
export const HXCS_ZAPP_FWGZL = 'HXCS_ZAPP_FWGZL'
export const HXCS_XM_SBMX = 'HXCS_XM_SBMX'
export const HXCS_YW_DCMBBC = 'HXCS_YW_DCMBBC'
export const HXCS_YW_GDDCMB = 'HXCS_YW_GDDCMB'
export const HXCS_YW_RWGDDC = 'HXCS_YW_RWGDDC'
export const HXCS_YW_DCMBSC = 'HXCS_YW_DCMBSC'
// 多仓库管理-新建
export const HXCS_CC_CKGX = 'HXCS_CC_CKGX'
// 多仓库管理-列表
export const HXCS_CC_CAKLB = 'HXCS_CC_CAKLB'
// 多仓库列表-仓库分类-删除
export const HXCS_YW_CKFLSC = 'HXCS_YW_CKFLSC'
// 多仓库分类-仓库分类列表
export const HXCS_YW_CKFLLB = 'HXCS_YW_CKFLLB'
// 多仓库分类-仓库分类-保存
export const HXCS_YW_CKFLBC = 'HXCS_YW_CKFLBC'
export const HXCS_CC_CKQTSC = 'HXCS_CC_CKQTSC'
export const HXCS_YW_CLFSXLK = 'HXCS_YW_CLFSXLK'
export const HXCS_CC_CKMXNEW = 'HXCS_CC_CKMXNEW'
export const HXCS_YW_ESRK = 'HXCS_YW_ESRK'
export const HXCS_CK_ESRKSC = 'HXCS_CK_ESRKSC'
export const HXCS_CC_KCPDMX = 'HXCS_CC_KCPDMX'
export const HXCS_CC_KCPD = 'HXCS_CC_KCPD'
export const HXCS_CC_KCPDBC = 'HXCS_CC_KCPDBC'
export const HXCS_CC_CPKCXQ = 'HXCS_CC_CPKCXQ'
export const HXCS_CC_CRKHZ = 'HXCS_CC_CRKHZ'
export const HXCS_YW_CKGZLB = 'HXCS_YW_CKGZLB'
export const HXCS_YW_CKGZBC = 'HXCS_YW_CKGZBC'
export const HXCS_CC_CKYJLB = 'HXCS_CC_CKYJLB'
export const HXCS_CC_CKYJBC = 'HXCS_CC_CKYJBC'
export const HXCS_CC_RKPLCL = 'HXCS_CC_RKPLCL'
export const HXCS_CC_KFDR = 'HXCS_CC_KFDR'
export const HXCS_CC_ESRKDR = 'HXCS_CC_ESRKDR'
export const HXCS_CC_CKDDDC = 'HXCS_CC_CKDDDC'
export const HXCS_XM_CKDDQX = 'HXCS_XM_CKDDQX'
export const HXCS_GH_CT_LIST = 'HXCS_GH_CT_LIST'
export const HXCS_CH_CT_HTXXBJ = 'HXCS_CH_CT_HTXXBJ'
export const HXCS_GD_LIST = 'HXCS_GD_LIST'
export const HXCS_GD_EDIT = 'HXCS_GD_EDIT'
export const HXCS_SPE_LIST = 'HXCS_SPE_LIST'
export const HXCS_BP_BSN_LIST = 'HXCS_BP_BSN_LIST'
export const HXCS_BP_LIST = 'HXCS_BP_LIST'
export const HXCS_BP_EDIT = 'HXCS_BP_EDIT'
export const HXCS_GD_EDITSTATE = 'HXCS_GD_EDITSTATE'
export const HXCS_GD_LIST_DETIL = 'HXCS_GD_LIST_DETIL'
export const HXCS_SPE_EDIT = 'HXCS_SPE_EDIT'
export const HXCS_SPE_EDIT_LIST = 'HXCS_SPE_EDIT_LIST'
export const HXCS_CH_CT_HTXXSC = 'HXCS_CH_CT_HTXXSC'
export const HXCS_GD_DELETE = 'HXCS_GD_DELETE'
export const HXCS_BP_DELETE = 'HXCS_BP_DELETE'
export const HXCS_SPE_LIST_SUM = 'HXCS_SPE_LIST_SUM'
export const HXCS_XM_XBHKJL = 'HXCS_XM_XBHKJL'
export const HXCS_YW_RWGDZT = 'HXCS_YW_RWGDZT'
export const HXCS_CLI_XGHTZT = 'HXCS_CLI_XGHTZT'
export const HXCS_YW_ZDJZFH = 'HXCS_YW_ZDJZFH'
export const HXCS_XM_HKXMTK = 'HXCS_XM_HKXMTK'
export const HXCS_XM_HKTKXG = 'HXCS_XM_HKTKXG'
export const HXCS_XM_XMTKJL = 'HXCS_XM_XMTKJL'
export const HXCS_XM_SBMXDC = 'HXCS_XM_SBMXDC'
export const HXCS_GD_BXCLB = 'HXCS_GD_BXCLB'
export const HXCS_XM_HKJHTHLB = 'HXCS_XM_HKJHTHLB'
export const HXCS_XM_HKJHTH = 'HXCS_XM_HKJHTH'
export const HXCS_XM_XMBYCLI = 'HXCS_XM_XMBYCLI'
export const HXCS_XM_STATISTIC = 'HXCS_XM_STATISTIC'
export const HXCS_XM_ZXMSTATISTIC = 'HXCS_XM_ZXMSTATISTIC'
export const HXCS_XM_HKSTATISTIC = 'HXCS_XM_HKSTATISTIC'
export const HXCS_XM_ZXMPLCJ = 'HXCS_XM_ZXMPLCJ'
export const HXCS_XM_MCQUERY = 'HXCS_XM_MCQUERY'
export const HXCS_XM_MCSAVE = 'HXCS_XM_MCSAVE'
export const HXCS_XM_MCDELETE = 'HXCS_XM_MCDELETE'
export const HXCS_PZ_KEHGXLB = 'HXCS_PZ_KEHGXLB' // 查询客户关系信息列表
export const HXCS_PZ_SAVE = 'HXCS_PZ_SAVE' // 新增/修改客户关系
export const HXCS_PZ_DELETE = 'HXCS_PZ_DELETE' // 删除客户关系
export const HXCS_CC_QDBJNEW = 'HXCS_CC_QDBJNEW'
export const HXCS_XM_XMSBXQNEW = 'HXCS_XM_XMSBXQNEW'
export const HXCS_CC_CKLIST = 'HXCS_CC_CKLIST'
export const HXCS_LOG_EQUIPMENTSEND = 'HXCS_LOG_EQUIPMENTSEND'
export const HXCS_XM_CKDDQXEQUIPMENT = 'HXCS_XM_CKDDQXEQUIPMENT'
export const HXCS_XM_XMCGDDQX = 'HXCS_XM_XMCGDDQX'
export const HXCS_KF_DSM = 'HXCS_KF_DSM'
export const HXCS_KF_DQR = 'HXCS_KF_DQR'
export const HXCS_KF_YQSM = 'HXCS_KF_YQSM'
export const HXCS_KF_YQWG = 'HXCS_KF_YQWG'
export const HXCS_KF_YQSC = 'HXCS_KF_YQSC'
export const HXCS_KF_TJ = 'HXCS_KF_TJ'
export const HXCS_YW_PZDK = 'HXCS_YW_PZDK'
export const HXCS_YW_DKQR = 'HXCS_YW_DKQR'
export const HXCS_YW_ARRIVA = 'HXCS_YW_ARRIVA'
export const HXCS_YW_RMBJ = 'HXCS_YW_RMBJ'
export const HXCS_XM_DDALLOT_BATCH = 'HXCS_XM_DDALLOT_BATCH'
export const HXCS_XM_DDALLOT = 'HXCS_XM_DDALLOT'
export const HXCS_GD_BXCLB_STATISTICS = 'HXCS_GD_BXCLB_STATISTICS'
export const HXCS_YW_STATISTICS = 'HXCS_YW_STATISTICS'
export const HXCS_GD_BXFXLB = 'HXCS_GD_BXFXLB'
export const HXCS_GD_BXFXTJ = 'HXCS_GD_BXFXTJ'
export const HXCS_XM_XMZCSUM = 'HXCS_XM_XMZCSUM'
export const HXCS_YW_RWAETIME = 'HXCS_YW_RWAETIME'
export const HXCS_PZ_BCMEINSERT = 'HXCS_PZ_BCMEINSERT'
export const HXCS_PZ_BCMEPAGE = 'HXCS_PZ_BCMEPAGE'
export const HXCS_PZ_BCMEDELETE = 'HXCS_PZ_BCMEDELETE'
export const HXOD_FEISHU_ORDER = 'HXOD_FEISHU_ORDER'
export const HXCS_XM_FEISHUSBMX = 'HXCS_XM_FEISHUSBMX'
export const HXCS_XM_XMSBQS = 'HXCS_XM_XMSBQS'
export const HXCS_XM_XMDDPLQX = 'HXCS_XM_XMDDPLQX'
export const HXCS_XM_FEISHUTASK = 'HXCS_XM_FEISHUTASK'
export const HXCS_XM_SENDAPPROVAL = 'HXCS_XM_SENDAPPROVAL'
export const HXCS_XM_QXDDALLOT = 'HXCS_XM_QXDDALLOT'
export const HXCS_XM_XMQXJX = 'HXCS_XM_XMQXJX'
export const HXCS_XM_ZXMK = 'HXCS_XM_ZXMK' // 获取全部数据--子项目列表
export const HXCS_XM_FXMK = 'HXCS_XM_FXMK' // 获取全部数据--父项目列表
export const HXCS_XM_ALLOT = 'HXCS_XM_ALLOT'
export const HXCS_ALLOT_PAGE_NEW = 'HXCS_ALLOT_PAGE_NEW'
export const HXCS_ALLOT_SBMX_LIST = 'HXCS_ALLOT_SBMX_LIST'
export const HXCS_FEISHU_CKSBXQ = 'HXCS_FEISHU_CKSBXQ'
export * from './serviceProviderActionName'
/**
 * 这个在接口请求的时候回拦截到的
 * 需要本地缓存处理的数据,防止过多的重复请求
 * 对应的接口会有相应的处理
 * getCode: 获取列表接口
 * editCode: 修改数据接口
 * delCode: 删除接口
 * matchKey: 对应数据唯一的 KEY ，用来找到本地数据并修改。
 */
export const CACHE_DATA_NAME = {
  // 完工时限
  serviceLevel: { getCode: HXCS_YW_FWSLAL, editCode: HXCS_YW_FWSLAA, delCode: HXCS_YW_FWSLAS, matchKey: 'slaId' },
  // 接单时限
  emergencylevel: { getCode: HXCS_YW_FWYXJL, editCode: HXCS_YW_FWYXJA, delCode: HXCS_YW_FWYXJS, matchKey: 'priorityId' },
  // 服务分类
  serviceClassify: { getCode: HXCS_YW_FWFLL, editCode: HXCS_YW_FWFLA, delCode: HXCS_YW_FWFLS },
  // 故障分类
  defectsClassify: { getCode: HXCS_YW_FWGZL, editCode: HXCS_YW_FWGZA, delCode: HXCS_YW_FWGZS },
  // 影响范围
  influenceSphere: { getCode: HXCS_YW_FWYXL, editCode: HXCS_YW_FWYXA, delCode: HXCS_YW_FWYXS },
  // 到达时限
  ReachLevel: { getCode: HXCS_YW_DDJBL, editCode: HXCS_YW_DDJBA, delCode: HXCS_YW_DDJBS }
  // 运维组
  // operationGroup: { getCode: HXCS_YW_YWQZL, editCode: HXCS_YW_YWQZA, dedlCode: HXCS_YW_YWQSC }
  // 字典数据
  // dictionary: { getCode: HXCS_QY_ZDLB, editCode: HXCS_QY_ZDAB, delCode: HXCS_QY_ZDSC }

}
