import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

// url对应的页面
let loginView = {
  'login': () => import('../views/login/index')
}
if (process.env.VUE_APP_COMPANYID === 'HDL') {
  loginView = {
    'login': () => import('../views/login/index_hdl')
  }
}

const views = {
  // 检索的时候会把最前面的'/'删除掉，所以这里不用带/了
  ...loginView,
  'register': () => import('../views/register'),
  'passwordBack': () => import('../views/passwordBack'),
  'resetPassword': () => import('../views/resetPassword'),
  'changePassword': () => import('../views/changePassword'),
  'upapprove': () => import('../views/upapprove'),
  'disabledAccount': () => import('../views/disabledAccount'),
  'settingSysRe': () => import('../views/settingSysRe'),
  'redirect': () => import('../views/redirect'),
  '404': () => import('../views/404'),
  'routerMenu': () => import('../views/routerMenu'),
  'operationStatistic': () => import('../views/operationStatistic'),
  'mainSettings': () => import('../views/systemSettings'),
  'assetDepreciation': () => import('../views/assetDepreciation'), // 资产折旧
  'userManage': () => import('../views/userManage'),
  'roleManage': () => import('../views/roleManage'),
  // 'groupManage': () => import('../views/groupManage'),
  'companyDict': () => import('../views/companyDict'),
  'levelSettings': () => import('../views/levelSettings'),
  'assetsStatistic': () => import('../views/assetsStatistic'),
  'jtyStatistic': () => import('../views/jtyDemo'),
  // 'servicelevel': () => import('../views/levelSettings/servicelevel'),
  // 'emergencylevel': () => import('../views/levelSettings/emergencylevel'),
  // 'serviceClassify': () => import('../views/serviceClassify'),
  // 'defectsClassify': () => import('../views/defectsClassify'),
  // 'influenceSphere': () => import('../views/influenceSphere'),
  // 'evaluateFeedback': () => import('../views/evaluateFeedback'),
  'divisionalManage': () => import('../views/divisionalManage'),
  'customerBoard': () => import('../views/companyMap'),
  'divisionalGroup': () => import('../views/divisionalGroup'),
  'operateManage': () => import('../views/operateManage'),
  'functionControl': () => import('../views/functionControl'),
  'attendanceManage': () => import('../views/attendanceManage'),
  'workingCalendar': () => import('../views/workingCalendar'),
  'assetManagement': () => import('../views/assetManagement'),
  'assetDirectory': () => import('../views/assetDirectory'),
  'partsDepot': () => import('../views/partsDepot'),
  'workorderManagement': () => import('../views/workorderManagement'),
  'serviceOrder': () => import('../views/serviceOrder'),
  'taskRelease': () => import('../views/taskRelease'),
  'taskOrder': () => import('../views/taskOrder'),
  'matterList': () => import('../views/matterList'),
  'rtim': () => import('../views/rtim/index'),
  'repository': () => import('../views/repository/index'),
  'reportProblem': () => import('../views/reportProblem/index'),
  'knownProblem': () => import('../views/knownProblem/index'),
  'plus': () => import('../views/plus'),
  'purchase': () => import('../views/purchase'),
  'businessConsult': () => import('../views/businessConsult'),
  // 服务商菜单 - 信息维护
  'informationMaintain': () => import('../serviceProviders/informationMaintain'),
  // 服务商菜单 - 人员管理
  'serviceUserManage': () => import('../serviceProviders/userManage'),
  // 服务商菜单 - 工单管理
  'serviceWorkorderManagement': () => import('../serviceProviders/workorderManagement'),
  // 服务商菜单 - 任务工单
  'serviceTaskOrderManage': () => import('../serviceProviders/taskOrderManage'),
  // 工单进度
  'orderProgress': () => import('../views/orderProgress'),
  'schedulingBench': () => import('../views/schedulingBench'), // 调度工作台
  'engineerWorkBench': () => import('../views/engineerWorkBench'), // 工程师工作台
  // 菜单管理
  'enterpriseMenuManage': () => import('../views/enterpriseMenuManage'),
  // 海底捞-字典管理
  'dictionaryDict': () => import('../views/haiDiLaoFiles/dictionaryDict'),
  // 海底捞-客户管理
  'clientManage': () => import('../views/haiDiLaoFiles/clientManage'),
  // 海底捞-合同管理页面
  'contractManage': () => import('../views/haiDiLaoFiles/contractManage'),
  // 仓库管理-项目订单
  'orderManagement': () => import('../views/haiDiLaoFiles/warehouseManagement/orderManagement'),
  // 仓库管理-库存明细
  'inventoryDetail': () => import('../views/haiDiLaoFiles/warehouseManagement/inventoryDetail'),
  // 仓库管理-入库管理
  /* 'stockManagement': () => import('../views/haiDiLaoFiles/warehouseManagement/stockManagement'), */
  // 仓库管理-入库管理-备货入库
  'readyWarehouse': () => import('../views/haiDiLaoFiles/warehouseManagement/stockManagement/readyWarehouse'),
  // 仓库管理-入库管理-退货入库
  'returnWarehouse': () => import('../views/haiDiLaoFiles/warehouseManagement/stockManagement/returnWarehouse'),
  // 仓库管理-入库管理-二手入库
  'secondWarehouse': () => import('../views/haiDiLaoFiles/warehouseManagement/stockManagement/secondWarehouse'),
  // 仓库管理-出库管理
  /* 'stockRemovalMangement': () => import('../views/haiDiLaoFiles/warehouseManagement/stockRemovalMangement'), */
  // 仓库管理-出库管理-备货出库
  'readyRemovalWarehouse': () => import('../views/haiDiLaoFiles/warehouseManagement/stockRemovalMangement/readyRemovalWarehouse'),
  // 仓库管理-出库管理-二手出库
  'returnRemovalWarehouse': () => import('../views/haiDiLaoFiles/warehouseManagement/stockRemovalMangement/returnRemovalWarehouse'),
  // 仓库管理-多仓库管理
  'multiWarehouseManagement': () => import('../views/haiDiLaoFiles/warehouseManagement/multiWarehouseManagement'),
  // 仓库管理-资产目录
  'assets': () => import('../views/haiDiLaoFiles/warehouseManagement/assetsManager/assetsList'),
  // 海底捞-销售任务页面
  'salesTask': () => import('../views/haiDiLaoFiles/salesTask'),
  'groupManage': () => import('../views/haiDiLaoFiles/groupMange/index'),
  // 海底捞-项目立项
  'projectManager': () => import('../views/haiDiLaoFiles/approvalProjecManger/projectManager'),
  'projectImplementation': () => import('../views/haiDiLaoFiles/approvalProjecManger/projectImplementation'),
  'projectReturnedMoney': () => import('../views/haiDiLaoFiles/approvalProjecManger/projectReturnedMoney'),
  'subProjectImplementation': () => import('../views/haiDiLaoFiles/approvalProjecManger/subProjectImplementation'),
  'subProjectManager': () => import('../views/haiDiLaoFiles/approvalProjecManger/subProjectManager'),
  'projectValuation': () => import('../views/haiDiLaoFiles/approvalProjecManger/projectValuation/projectValuationList'), // 项目计价
  // 海底捞-采购管理
  'supplierInfo': () => import('../views/haiDiLaoFiles/purchase/supplierList'), // 供应商信息
  'supplierPurchase': () => import('../views/haiDiLaoFiles/purchase/supplierPurchase'), // 供货商采购
  'puchaseRequest': () => import('../views/haiDiLaoFiles/purchase/purchaseRequest'), // 采购申请
  'computeStatistics': () => import('../views/haiDiLaoFiles/financialSettlement/computeStatistics'), // 结算统计
  'settlementRules': () => import('../views/haiDiLaoFiles/financialSettlement/settlementRules'), // 结算规则
  'todoWorkbench': () => import('../views/haiDiLaoFiles/todoWorkbench'), // 待办工作台
  'logQuery': () => import('../views/haiDiLaoFiles/logQuery'), // 日志查询
  'collectionDetails': () => import('../views/collectionDetails'),
  'projectReconciliation': () => import('../views/haiDiLaoFiles/approvalProjecManger/projectReconciliation'), // 项目对账
  'projectExpenditure': () => import('../views/haiDiLaoFiles/approvalProjecManger/projectExpenditure'), // 项目支出
  'bankWithdrawal': () => import('../views/haiDiLaoFiles/approvalProjecManger/bankWithdrawal'), // 项目银行回款
  'messageManage': () => import('../views/haiDiLaoFiles/message/notice'), // 消息中心
  'productOutSummary': () => import('../views/haiDiLaoFiles/warehouseManagement/productOutSummary'), // 产品出库汇总
  'warehouseOrder': () => import('../views/haiDiLaoFiles/warehouseManagement/warehouseOrder'), // 仓库订单
  'checkProject': () => import('../views/haiDiLaoFiles/approvalProjecManger/checkProject'),
  'businessAnalysis': () => import('../views/haiDiLaoFiles/financialSettlement/businessAnalysis'), // 经营分析数据看板
  'invoicDetails': () => import('../views/haiDiLaoFiles/approvalProjecManger/invoicDetails'), // 项目开票明细
  'projectInvoicApply': () => import('../views/haiDiLaoFiles/financialSettlement/projectInvoicApply'), // 项目开票申请
  'returngoods': () => import('../views/haiDiLaoFiles/purchase/returngoods'), // 采购设备退货申请
  /* 'housereturngoods': () => import('../views/haiDiLaoFiles/warehouseManagement/housereturngoods'), // 仓库设备退货申请 */
  'projectReturnProcessing': () => import('../views/haiDiLaoFiles/warehouseManagement/housereturngoods/projectReturnProcessing'), // 项目退货处理
  'warehouseReturnApplication': () => import('../views/haiDiLaoFiles/warehouseManagement/housereturngoods/warehouseReturnApplication'), // 仓库退货申请
  'equipmentAllReturn': () => import('../views/haiDiLaoFiles/approvalProjecManger/equipmentAllReturn'), // 设备退货汇总
  'productBrand': () => import('../views/haiDiLaoFiles/purchase/productBrand'), // 产品品牌
  'commissionRate': () => import('../views/commissionRate'), // 系统设置/提成比例
  'parameterSetting': () => import('../views/parameterSetting'), // 系统设置/参数设置
  /* 'settlementAudit': () => import('../views/settlementAudit'),  */// 工单管理/结算审核
  'feedbackAudit': () => import('../views/settlementAudit/feedbackAudit'), // 工单管理/结算审核
  'receiptsAudit': () => import('../views/settlementAudit/receiptsAudit'), // 工单管理/单据审核
  'certificateReceiptAudit': () => import('../views/settlementAudit/certificateReceiptAudit'), // 工单管理/验收单审核
  'performanceReport': () => import('../views/performanceReport'), // 工单管理/绩效报表
  'performanceEvaluation': () => import('../views/performanceEvaluation'), // 运维管理/绩效评定
  'workOrderDenominated': () => import('../views/workOrderDenominated'), // 立项管理/工单计价
  'invoiceProcessingRecord': () => import('../views/haiDiLaoFiles/financialSettlement/invoiceProcessingRecord'), // 财务结算/开票处理记录
  'projectEquipmentDetails': () => import('../views/projectEquipmentDetails'), // 立项管理/项目设备明细
  'equipmentAllocationDetails': () => import('../views/equipmentAllocationDetails'), // 立项管理/设备调拨明细
  'outboundWarning': () => import('../views/haiDiLaoFiles/warehouseManagement/outboundWarning'), // 出库预警
  'warehouseInventory': () => import('../views/haiDiLaoFiles/warehouseManagement/warehouseInventory'), // 库房盘点
  'stockBatchDetails': () => import('../views/haiDiLaoFiles/warehouseManagement/stockBatchDetails'), // 库存批次明细
  /* 供货商管理 */
  /* 'supplierManagement': () => import('../views/supplierManagement'), */ // 供货商管理
  'supplierContract': () => import('../views/supplierManagement/supplierContract'), // 供货商管理-供货商合同
  'supplierReconciliation': () => import('../views/supplierManagement/supplierReconciliation'), // 供货商管理-供货商对账
  'supplierOrder': () => import('../views/supplierManagement/supplierOrder'), // 供货商管理-供货商订单
  'bankPaymentStatement': () => import('../views/supplierManagement/bankPaymentStatement'), // 供货商管理-银行支付流水
  'repairOrderManagement': () => import('../views/repairOrderManagement'), // 工单管理-报修池
  'customerServiceCenter': () => import('../views/customerServiceCenter'), // 工单管理-客服中心工作台
  'arrivePunchEngineer': () => import('../views/arrivePunchEngineer'), // 工单管理-到达打卡工程师
  'repairWorkOrder': () => import('../views/serviceCounter/repairWorkOrder'), // 服务台-报修待受理
  'waitingWorkOrder': () => import('../views/serviceCounter/waitingWorkOrder'), // 服务台-报修待受理
  'repairAccepted': () => import('../views/repairManagement/repairAccepted'), // 报修管理-已受理报修
  'repairAnalysis': () => import('../views/repairManagement/repairAnalysis'), // 报修管理-报修分析
  'workOrderStatistics': () => import('../views/workOrderStatistics'), // 售后工单统计
  'orderList': () => import('../views/projectOrderManagement/orderList'), // 订单管理-订单列表
  'transferDetails': () => import('../views/projectOrderManagement/transferDetails'), // 订单管理-调拨明细
  'returnDetails': () => import('../views/projectOrderManagement/returnDetails'), // 订单管理-退货明细
  'equipmentDetail': () => import('../views/projectOrderManagement/equipmentDetail') // 订单管理-设备明细

  // 'mainMenuManage': () => import('../views/mainMenuManage/index')
  // 'mainUserManage': () => import('../views/mainUserManage/index'),
  // 'mainRoleManage': () => import('../views/mainRoleManage/index'),
  // 'enterpriseMenuManage': () => import('../views/enterpriseMenuManage/index'),
  // 'enterpriseMemberManage': () => import('../views/enterpriseMemberManage/index'),
  // 'enterpriseManage': () => import('../views/enterpriseManage/index'),
  // 'carouselMapManage': () => import('../views/carouselMapManage/index'),
  // 'floatingBtnManage': () => import('../views/floatingBtnManage/index'),
  // 'propertyInfo': () => import('../views/propertyInfo/index'),
  // 'marketList': () => import('../views/marketList/index'),
  // 'shopType': () => import('../views/shopType/index'),
  // 'shopList': () => import('../views/shopList/index'),
  // 'coupon': () => import('../views/discountCoupon/index'),
  // 'admissionTicket/:id': () => import('../views/discountCoupon/admissionTicket/index'),
  // 'receivedUserList/:id': () => import('../views/discountCoupon/receivedUserList/index'),
  // 'activeInfo': () => import('../views/activeInfo/index'),
  // 'activeInfoEdit/:id': () => import('../views/activeInfo/editActiveInfo/index'),
  // 'activeInfoDetail/:id': () => import('../views/activeInfo/activeInfoDetail/index'),
  // 'activeJoinPerson/:id': () => import('../views/activeInfo/activememberList/index'),
  // 'memberList': () => import('../views/ykhmemberList/index'),
  // 'highQualityLife': () => import('../views/highQualityLife/index'),
  // 'marketFloor/:id': () => import('../views/marketList/marketFloor/index'),
  // 'homeEventPromotion': () => import('../views/homeEventPromotion/index'),
  // 'activeCarouselMap': () => import('../views/activeCarouselMap/index'),
}
// 获取页面方法
export const getView = (path) => {
  let p = path
  if (p.substring(0, 1) === '/') {
    p = p.substr(1)
  }
  const view = views[p]
  if (view) {
    return view
  } else {
    console.log('wwwlin-页面没找到', p)
    return views['404']
  }
}

/**
 * 这个是默认的路由，有没有权限都可以访问的
 */
export const constantRoutes = [
  // 需要返回的页面
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: getView('/redirect')
      }
    ]
  },
  // 登录页面
  {
    path: '/login',
    component: getView('/login'),
    meta: {
      title: 'login'
    },
    hidden: true
  },
  // 注册页面
  {
    path: '/register',
    component: getView('/register'),
    meta: {
      title: 'register'
    },
    hidden: true
  },
  // 密码找回页面
  {
    path: '/passwordBack',
    component: getView('/passwordBack'),
    meta: {
      title: 'passwordBack'
    },
    hidden: true
  },
  // 修改密码页面
  {
    path: '/resetPassword',
    // component: getView('/resetPassword'),
    component: getView('/changePassword'),
    meta: {
      title: '修改密码'
    },
    hidden: true
  },
  // 修改密码页面 2
  {
    path: '/changePassword',
    component: getView('/changePassword'),
    meta: {
      title: '修改密码'
    },
    hidden: true
  },
  {
    path: '/dashboard/jtyStatistic',
    component: getView('/jtyStatistic'),
    meta: {
      title: '京铁云运维数据'
    },
    hidden: true
  },
  // 错误位置页面
  {
    path: '/404',
    component: getView('/404'),
    hidden: true
  },
  // {
  //   path: '/companyMap',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/companyMap',
  //       component: getView('/companyMap'),
  //       meta: {
  //         title: 'companyMap'
  //       },
  //       hidden: true
  //     }
  //   ],
  //   hidden: true
  // },
  {
    path: '/plus',
    component: Layout,
    children: [
      {
        path: '/plus',
        component: getView('/plus'),
        name: 'plus',
        meta: {
          title: '会员Plus'
        },
        hidden: true
      }
    ],
    hidden: true
  },
  {
    path: '/purchase',
    component: Layout,
    children: [
      {
        path: '/purchase',
        component: getView('/purchase'),
        name: 'purchase',
        meta: {
          title: '购买记录'
        },
        hidden: true
      }
    ],
    hidden: true
  },
  // 默认使用框架的页面。
  {
    path: '/',
    component: Layout,
    // redirect: '/dashboard',
    children: [
      // {
      //   path: 'dashboard',
      //   // 默认的主页页面
      //   component: getView('dashboard'),
      //   name: 'dashboard',
      //   // hidden: true,
      //   meta: {
      //     title: 'dashboard',
      //     icon: 'iconshouye',
      //     affix: true
      //   }
      // },
      // {
      //   path: '/dashboard/todoWorkbench',
      //   component: getView('/todoWorkbench'),
      //   name: 'todoWorkbench',
      //   meta: {
      //     title: '待办事项',
      //     affix: true
      //   },
      //   hidden: true,
      //   children: [
      //     { perms: 'checkClient', path: '' }
      //   ]
      // },
      {
        path: '/upapprove',
        component: getView('/upapprove'),
        name: 'upapprove',
        meta: {
          title: 'upapprove'
        },
        hidden: true
      },
      {
        path: '/disabledAccount',
        component: getView('/disabledAccount'),
        meta: {
          title: 'disabledAccount'
        },
        hidden: true
      },
      {
        path: '/projectApprovalManager/projectManager/checkProject',
        component: getView('checkProject'),
        name: 'projectManagerCheck',
        meta: {
          title: '项目管理',
          noCache: true,
          breadcrumb: false
        },
        hidden: true
      },
      {
        path: '/projectApprovalManager/bankWithdrawal2',
        component: getView('/bankWithdrawal'),
        name: 'bankWithdrawalbankWithdrawal',
        meta: {
          title: 'bankWithdrawal',
          noCache: true,
          breadcrumb: false
        },
        hidden: true
      }
    ]
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
